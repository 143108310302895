import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import RefundPolicyPage from "../../components/legal/refund-policy"
const refundpolicy = () => {
  return (
    <Layout>
      <SEO
        title="refund-policy"
        desc="refund-policy"
        pathname="/refund-policy"
        keywords="kyts refund-policy"
      />
      <RefundPolicyPage />
    </Layout>
  )
}

export default refundpolicy
